import React from "react";
import { useXR } from "@react-three/xr";

import { Model, ShapeTexture, AIModel, UIModelTexture, UploadedModel } from "./Model";
import {TextObject} from "./TextObject"
import {ImageObject} from "./ImageObject";
import {FigmaObject} from "./FigmaObject";
import { useStore } from "../zustand/objects";

export const ObjectsMapped = ({
  orbitControls,
  objectDetails,
  objectsAreLoaded,
  currentObjectArtboard,
  undoing,
  artboards
}) => {
  const {groupedObjects} = useStore()
  
  // const xr = useXR()

  if (!objectsAreLoaded) {
    return ;
  } else {
    return(artboards[currentObjectArtboard].map((mapped) => (

     !groupedObjects.includes(mapped.id) && 
     !mapped.content ? (mapped.id === "camera") ? 
     ''
        : (mapped.category === "image") ?
        <ImageObject
            key={mapped.id}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            category={mapped.category}
            orbitControls={orbitControls}
            objectDetails={objectDetails}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            undoing={undoing} 
            colour={mapped.colour}
            pose={mapped.pose}
            image={mapped.image}
            imageSize={mapped.imageSize}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
            curved={mapped.curved}
            curveAmount={mapped.curveAmount}
          /> : mapped.uploadedURL && (mapped.category === "uploaded") ? 
          <UploadedModel
            key={mapped.id}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            category={mapped.category}
            orbitControls={orbitControls}
            objectDetails={objectDetails}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            undoing={undoing} 
            colour={mapped.colour}
            pose={mapped.pose}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
            uploadedURL={mapped.uploadedURL}
            uploadId={mapped.uploadId}
          />  :
           (mapped.category === "figma") ?
        <FigmaObject
            key={mapped.id}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            category={mapped.category}
            orbitControls={orbitControls}
            objectDetails={objectDetails}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            undoing={undoing} 
            colour={mapped.colour}
            figmaID={mapped.figmaID}
            figmaURL={mapped.figmaURL}
            figmaSize={mapped.figmaSize}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
            curved={mapped.curved}
            curveAmount={mapped.curveAmount}
          /> :
          mapped.databaseId && (mapped.category === "ai") ?
            <AIModel
            key={mapped.id}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            prompt={mapped.object}
            category={mapped.category}
            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
            orbitControls={orbitControls}
            objectDetails={objectDetails}
            artboard={artboards[currentObjectArtboard]}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            undoing={undoing}
            colour={mapped.colour}
            pose={mapped.pose}
            imageTexture={mapped.imageTexture}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
            databaseId={mapped.databaseId}
            databaseURL={mapped.databaseURL}
          /> :
          (mapped.imageTexture && mapped.category === "shape") ?
          <ShapeTexture
              key={mapped.id}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
              orbitControls={orbitControls}
              objectDetails={objectDetails}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              undoing={undoing}
              colour={mapped.colour}
              pose={mapped.pose}
              imageTexture={mapped.imageTexture}
              isLocked={mapped.isLocked}
              matrixState={mapped.matrixState}
            />
            :
            mapped.imageTexture && (mapped.category === "UI") ?
          <UIModelTexture
              key={mapped.id}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
              orbitControls={orbitControls}
              objectDetails={objectDetails}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              undoing={undoing}
              colour={mapped.colour}
              pose={mapped.pose}
              imageTexture={mapped.imageTexture}
              isLocked={mapped.isLocked}
              matrixState={mapped.matrixState}
            />
            :
            <Model
              key={mapped.id}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
              orbitControls={orbitControls}
              objectDetails={objectDetails}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              undoing={undoing}
              colour={mapped.colour}
              pose={mapped.pose}
              isLocked={mapped.isLocked}
              matrixState={mapped.matrixState}
              imageTexture={mapped.imageTexture}
              curved={mapped.curved}
              curveAmount={mapped.curveAmount}
              modelPath={mapped.modelPath}
              // isVR={xr.isPresenting}
            />
        : !groupedObjects.includes(mapped.id) &&  
        <TextObject content={mapped.content}
          key={mapped.id}
          position={mapped.position}
          x={mapped.position.x}
          y={mapped.position.y}
          z={mapped.position.z}
          rotation={mapped.rotation}
          scale={mapped.scale}
          id={mapped.id}
          destination={mapped.destination}
          object={mapped.object}
          category={mapped.category}
          url={"./model/" + mapped.category + "/" + mapped.object + ".glb"}
          orbitControls={orbitControls}
          objectDetails={objectDetails}
          artboard={artboards[currentObjectArtboard]}
          currentObjectArtboard={currentObjectArtboard}
          objectsAreLoaded={objectsAreLoaded}
          undoing={undoing}
          colour={mapped.colour}
          bGColour={mapped.bGColour}
          isLocked={mapped.isLocked}
          matrixState={mapped.matrixState}
        />
    )
    )
    )
  }
};

