import React, { useState, useRef, Suspense } from "react";



import { useStore } from "../zustand/objects";
// react three fiber takes care of camera position in canvas
import { Canvas, useThree } from "@react-three/fiber";
import { XR,  useXR, Controllers, TeleportationPlane, useXREvent } from "@react-three/xr";
// drei have lots of shapes without writing code
import { OrbitControls, BakeShadows, Sky, View  } from "@react-three/drei";
import { Loader } from "@mantine/core"
import { ObjectsMapped } from "./ObjectsMapped";

import Cursor from "./Cursor";
import PlayerFigure from "./PlayerFigure";
import CaptureCursor from "./CaptureCursor";
import Skybox from "./Skybox";
import { GroupedObjectsMapped } from "./GroupedObjectsMapped";
import { VRTeleportFloorGrid } from './TeleportFloorGrid'
import {CaptureHeadset} from "./CaptureHeadset";

const Viewport = ({ 
  objectDetails,
  undoing,
  artboards,
  projectKey
}) => {
  const orbitControls = useRef();
  
  const { objectsAreLoaded, currentObjectArtboard, floorColour, groupedObjects, transformMode, isMovingVRObject } = useStore();
  
  const COLORS = [
    "#E57373",
    "#9575CD",
    "#4FC3F7",
    "#81C784",
    "#FFF176", 
    "#FF8A65",
    "#F06292",
    "#7986CB",
  ];

  // we need to map the others in the room up here somewhere in an array
  // then we need to subscribe each cursor to the positions, that can change,
  // potentially put these cursor positions in zustand, 
  // so changes can be fed to the mapped cursors without rerendering them
  // that way, we might be able to animate the transition between cursor positions

  // used to be from liveblocksStore


  // - - - -  cursors end


  const canvasRef = useRef()

  // - - - -  cursors end

  // vr stuff start
  const [showTiles, setShowTiles] = useState(false);

  const changeTiles = (e) => setShowTiles(e)

  // vr stuff end


  if (!objectsAreLoaded) {
    return <div style={{position:"absolute", left:"50%", top:"50%", width:"100%", height:"100%"}}><Loader color="black" /></div>;
    // return <div>Loading...</div>;
  } else {
    return (
      <Suspense fallback={null}>
        {/* <VRButton sessionInit={{ optionalFeatures: ['hand-tracking'] }} /> */}
        <Canvas
        id="viewport_canvas"
          shadows
          camera={{
            position: [0, 10, 10],
            fov: 25
          }} 
          style={{
            position: "fixed",
            width: "100%",
            height: "100%"
          }} 
          alpha="false"
          dpr={window.devicePixelRatio}
          frameloop="demand"
          ref={canvasRef}>
            <XR>
            <TeleportationPlane leftHand={false} rightHand={true} maxDistance={10} size={0.5}/> 

            <CaptureHeadset currentObjectArtboard={currentObjectArtboard}/>
          <color attach="background" args={["#d8d8d8"]} />
          <Sky distance={4500000} sunPosition={[0, 26, 0]} inclination={0} azimuth={0.25} />
          {/* <Skybox/> */}
          <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
            
          <OrbitControls
            dampingFactor={1}
            keyPanSpeed={1500}
            zoomSpeed={1}
            maxDistance={100}
            minDistance={1}
            enableZoom={true}
            enableDamping={true}
            enablePan={true}
            screenSpacePanning={true}
            ref={orbitControls}
            makeDefault
          />
            <Controllers rayMaterial={{ color: 'blue' }} />
          <group>
            <BakeShadows />
            <Suspense fallback={null}>
              <mesh
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, 0, 0]}
              // onPointerMove={(e) => handlePointerMoveSet({cameraPosition: new THREE.Vector3().unproject(e.camera), point: e.point, lookAt: e.ray.direction})}
              // onClick={() => missedShape()}
              >
                <planeGeometry attach="geometry" args={[50, 50]} />
                <meshStandardMaterial
                  color={floorColour}
                  attach="material"
                  opacity={1}
                  receiveShadow
                />
              </mesh>
            </Suspense>
            {!isMovingVRObject && <SetTilesFunction changeTiles={changeTiles}/>}
            { showTiles ? <VRTeleportFloorGrid /> : ''}
           <Suspense fallback={null}>
              <ObjectsMapped
                artboards={artboards}
                orbitControls={orbitControls}
                objectDetails={objectDetails}
                currentObjectArtboard={currentObjectArtboard}
                objectsAreLoaded={objectsAreLoaded}
                undoing={undoing}
              />
            </Suspense>

            {groupedObjects.length >= 1 && <Suspense fallback={null}>
              <GroupedObjectsMapped
                artboards={artboards}
                orbitControls={orbitControls}
                objectDetails={objectDetails} 
                currentObjectArtboard={currentObjectArtboard}
                objectsAreLoaded={objectsAreLoaded}
                undoing={undoing}
              />
            </Suspense>}

             
            
            {/* <Person/> */}
           
          </group>
          <gridHelper scale={0.5} position={[0, 0, 0]} args={[100, 100]} />
        <CursorsAndHeadsets currentObjectArtboard={currentObjectArtboard} COLORS={COLORS} transformMode={transformMode} projectKey={projectKey}/>
         
        </XR>
        </Canvas>
      </Suspense>

    );
  }
};

export default Viewport;

const CursorsAndHeadsets = ({currentObjectArtboard, COLORS, transformMode, projectKey}) => {




  // console.log(scene)

  const others = useStore((state) => state.liveblocks.others);

  return (
    <>
    {/* CURSORS */}
      <Suspense fallback={null}>
        {others && others.map(({ connectionId, presence }) =>
          presence.cursor && presence.cursor.page === 'editor' && presence.cursor.artboard === currentObjectArtboard && <Cursor key={connectionId} rotation={presence.cursor.rotation} position={presence.cursor.position} color={COLORS[connectionId % COLORS.length]} mouse={presence.cursor.mouse} />
        )}</Suspense>
        {/* HEADSETS */}
      <Suspense fallback={null}>
        {others && others.map(({ connectionId, presence }) =>
          presence.headset && presence.preview && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.headset.rotation} position={presence.headset.position} color={COLORS[connectionId % COLORS.length]} device={presence.preview.device} />
          // presence.preview && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.preview.rotation} position={presence.preview.position} color={COLORS[connectionId % COLORS.length]} device={presence.preview.device} />
        )}</Suspense>
        {/* CAPTURING EDITOR CURSOR */}
        {others && transformMode !== 'scale' && <CaptureCursor currentObjectArtboard={currentObjectArtboard}/>}
    </>
  )

}

const SetTilesFunction = ({changeTiles}) => {
  
 let timer

const handleLongPress = () => {
  timer = setTimeout(() => changeTiles(true), 900)
}

const handleButtonRelease = () => {
  clearTimeout(timer)
  changeTiles(false)
}
useXREvent('selectstart', handleLongPress)
useXREvent('selectend', handleButtonRelease)
}


export function Person() {

  // const url = "https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/730a12e6-9bf3-4306-a4ca-dbe8db042b39"

  
  // const texture = useTexture(url)

   
  return (<>
     {/* <LargeHall/> */}
     {/* <LShapedRoom/> */}
     {/* <WithWindowSquareRoom/> */}
     {/* <Hallway/> */}
     {/* <NoWindowSquareRoom/> */}
      </>
);
}

