import React, { useEffect, useRef, Suspense,useMemo } from "react";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { useStore } from "../zustand/objects";
import {PreviewModel, ShapeTexture, PreviewAIModel, UIModelTexture, PreviewUploadedModel} from "./PreviewModel";
import {PreviewFigmaObject} from "./FigmaObject";
import Cursor from "./Cursor";
import PlayerFigure from "./PlayerFigure";
import PreviewText from "./PreviewText";
import PreviewImageObject from "./PreviewImageObject";
import "../dot.css";
import { Sky } from "@react-three/drei";


export const ScreenshotGuest = ({ artboard, cameraGuest, id }) => {

  const canvasRef = useRef()

  const {
    artboards,
    objectsAreLoaded,
    currentObjectArtboard, 
    floorColour,
    updatePreviewCamera
  } = useStore();

  // const others = useStore((state) => state.liveblocks.others);

  // useEffect(() => {
  //   console.log(cameraGuest.position)
  // },[cameraGuest.position.z])


const COLORS = [
  "#E57373",
  "#9575CD",
  "#4FC3F7",
  "#81C784",
  "#FFF176",
  "#FF8A65",
  "#F06292",
  "#7986CB",
]; 

  useEffect(() => {
    updatePreviewCamera('position', [0,0,0])
    updatePreviewCamera('rotation', [0,0,0])
    
  }, [])

  function PreviewCameraFunction() {
    const { camera } = useThree()
    useFrame(() => {
      
      camera.position.x = cameraGuest.position.x
      camera.position.y =  cameraGuest.position.y
      camera.position.z =  cameraGuest.position.z
      camera.rotation.x = cameraGuest.rotation._x
      camera.rotation.y = cameraGuest.rotation._y
      camera.rotation.z = cameraGuest.rotation._z
 
    })
    return null
  }

    return <Suspense fallback={<div><img src="/image/loading.gif" style={{ width: "50px", height: "50px" }} /></div>}>

      <>
    <Canvas
      style={{
        top: 0, 
        left: 0,
        width: "100%",
        height: "100%",
        // height: "auto",
        position: 'relative'
      }}
      camera={{
        position: cameraGuest.position && [cameraGuest.position.x, 1.75, cameraGuest.position.z + 2],
        rotation: cameraGuest.rotation && [cameraGuest.rotation._x, cameraGuest.rotation._y, cameraGuest.rotation._z],
        fov: 55
      }}
      frameloop="demand"
      shadows
      gl={{ preserveDrawingBuffer: true }}
      ref={canvasRef}
      >
      
      {cameraGuest.position && <PreviewCameraFunction/>}
             <Sky distance={4500000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
             <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>

      {artboards[artboard] && artboards[artboard].map((mapped, i) => (
        !mapped.content ?
          (mapped.object === "camera")
            ? ''
            : (mapped.category === "image") ? 
            <PreviewImageObject
              key={i}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              pose={mapped.pose}
              image={mapped.image}
              imageSize={mapped.imageSize}
              matrixState={mapped.matrixState}
              curved={mapped.curved}
              curveAmount={mapped.curveAmount}
            />  : (mapped.category === "figma") ?
            <PreviewFigmaObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={1}
                  colour={mapped.colour}
                  figmaID={mapped.figmaID}
                  figmaURL={mapped.figmaURL}
                  figmaSize={mapped.figmaSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                /> :
                            mapped.databaseId && (mapped.category === "ai") ?
            <PreviewAIModel
            key={mapped.id}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            prompt={mapped.object}
            category={mapped.category}
            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
            artboard={artboards[currentObjectArtboard]}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            colour={mapped.colour}
            pose={mapped.pose}
            imageTexture={mapped.imageTexture}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
            databaseId={mapped.databaseId}
            databaseURL={mapped.databaseURL}
          />  :
          mapped.uploadedURL && (mapped.category === "uploaded") ?
            <PreviewUploadedModel
            key={mapped.id}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            object={mapped.object}
            prompt={mapped.object}
            category={mapped.category}
            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
            artboard={artboards[currentObjectArtboard]}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            colour={mapped.colour}
            pose={mapped.pose}
            imageTexture={mapped.imageTexture}
            isLocked={mapped.isLocked}
            matrixState={mapped.matrixState}
            uploadedURL={mapped.uploadedURL}
            uploadId={mapped.uploadId}
          />  :
          mapped.imageTexture && (mapped.category === "UI") ?
          <UIModelTexture
              key={mapped.id}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              pose={mapped.pose}
              imageTexture={mapped.imageTexture}
              isLocked={mapped.isLocked}
              matrixState={mapped.matrixState}
              curved={mapped.curved}
              curveAmount={mapped.curveAmount}
            />
            :
            (mapped.imageTexture && mapped.category === "shape") ?
      <ShapeTexture
      key={i}
      position={mapped.position}
      rotation={mapped.rotation}
      scale={mapped.scale}
      id={mapped.id}
      destination={mapped.destination}
      object={mapped.object}
      category={mapped.category}
      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
      artboard={artboards[currentObjectArtboard]}
      currentObjectArtboard={currentObjectArtboard}
      objectsAreLoaded={objectsAreLoaded}
      colour={mapped.colour}
      pose={mapped.pose}
      imageTexture={mapped.imageTexture}
      matrixState={mapped.matrixState}
    /> :
            <PreviewModel 
              key={i}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              object={mapped.object}
              category={mapped.category}
              url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
              artboard={artboards[artboard]}
              currentObjectArtboard={artboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              pose={mapped.pose}
              matrixState={mapped.matrixState}
              curved={mapped.curved}
              curveAmount={mapped.curveAmount}
              imageTexture={mapped.imageTexture}
              modelPath={mapped.modelPath}
            />
          :
          <PreviewText
            key={i}
            content={mapped.content}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            artboard={artboards[artboard]}
            currentObjectArtboard={artboard}
            objectsAreLoaded={objectsAreLoaded}
            colour={mapped.colour}
            bGColour={mapped.bGColour}
            matrixState={mapped.matrixState}
          />
      ))}


      <mesh
        receiveShadow
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, 0, 0]}
      >
        <planeGeometry attach="geometry" args={[50, 50]} />
        <meshStandardMaterial
              color={floorColour}
              attach="material"
              opacity={1}
            />
          </mesh>
          {/* <Suspense fallback={null}>
            {others && others.map(({ connectionId, presence }) =>
              presence.cursor && presence.cursor.page === 'editor' && presence.cursor.artboard === currentObjectArtboard && <Cursor key={connectionId} rotation={presence.cursor.rotation} position={presence.cursor.position} color={COLORS[connectionId % COLORS.length]} mouse={presence.cursor.mouse} />
            )}</Suspense>
          <Suspense fallback={null}>
            {others && others.map(({ connectionId, presence }) =>
              presence.preview && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && connectionId !== id && <PlayerFigure key={connectionId} rotation={presence.preview.rotation} position={presence.preview.position} color={COLORS[connectionId % COLORS.length]} />
            )}</Suspense> */}
    </Canvas>
    </>
    </Suspense>
  }
 


