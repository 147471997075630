import React, { useEffect, useRef, Suspense, useState, useLayoutEffect } from "react";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { useStore } from "../zustand/objects";
import { PreviewModel, ShapeTexture, PreviewAIModel, UIModelTexture, PreviewUploadedModel } from "./PreviewModel";
import PreviewText from "./PreviewText";
import PreviewImageObject from "./PreviewImageObject";
import { PreviewFigmaObject } from "./FigmaObject";
import { saveUserScreenshotsAPI } from "../api"
import "../dot.css";
import { Sky, View, Preload } from "@react-three/drei";
import * as THREE from "three";
 
const TakeScreenshot = ({ artboard, guestMode, projectKey, updateFunction }) => {
  
  const { 
    objectsAreLoaded, 
    addScreenshot,
    screenshotStatus
  } = useStore();

  const { gl, scene, camera } = useThree()

  

  const screenshotFunction = () => {
    addScreenshot(artboard, gl.domElement.toDataURL('image/png', 0.25)) 
    updateFunction()
  }

  useEffect(() => {
    screenshotStatus && screenshotFunction()

    artboard && window.setTimeout(function () {
      screenshotStatus && screenshotFunction()
  }, 1000);
  },[])
}

const OtherFunctions = () => {

  const [ready, setReady] = useState(false)
  const [taken, setTaken] = useState(false)

  const {
    objectsAreLoaded,
    addScreenshot
  } = useStore();

  const { gl, scene, camera } = useThree()

  const positionCamera = () => {
    const deg2rad = degrees => degrees * (Math.PI / 180);
    camera.rotation.set(deg2rad(0), 0, 0)

    setReady(true)
    return ''
  }



  useEffect(() => {
    positionCamera()
    // if (ready && !taken)  {takeScreenshot()}

  }, [])


  return ''
}


export const Screenshot = ({ index, artboard, guestMode, projectKey, updateScreenshotArtboard, snapping }) => {



  const canvasRef = useRef()



  const {
    artboards,
    screenshotStatus,
    previewCameraPosition,
    previewCameraRotation,
    objectsAreLoaded,
    currentObjectArtboard,
    floorColour,
    updatePreviewCamera
  } = useStore();

  useEffect(() => {
    updatePreviewCamera('position', [0, 0, 0])
    updatePreviewCamera('rotation', [0, 0, 0])

  }, [])

  function PreviewCameraFunction() {
    const { camera } = useThree()
    // console.log(camera)
    useFrame(() => {
      // camera.position.x = artboards[artboard].find((x) => x.id === "camera").position[0]
      // camera.position.z = artboards[artboard].find((x) => x.id === "camera").position[2]
      camera.position.x = previewCameraPosition[0]
      camera.position.y = previewCameraPosition[1] + 1.75
      camera.position.z = previewCameraPosition[2]

      camera.rotation.x = previewCameraRotation[0]
      camera.rotation.y = previewCameraRotation[1]
      camera.rotation.z = previewCameraRotation[2]

    })
    return null
  }

  const updateFunction = () => {
    updateScreenshotArtboard()
  }

  // return <Suspense fallback={<div><img src="/image/loading.gif" style={{width: "50px", height: "50px"}}/></div>}><>
  return (
    <>
      <Canvas
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        camera={{
          position: [previewCameraPosition[0], previewCameraPosition[1] + 1.75, previewCameraPosition[2] + 2],
          rotation: [previewCameraRotation[0], previewCameraRotation[1], previewCameraRotation[2]],
          fov: 55
        }}
        // updateDefaultCamera={true}
        frameloop="demand"
        shadows
        gl={{ preserveDrawingBuffer: true }}
        ref={canvasRef}
      >
        {<TakeScreenshot updateFunction={updateScreenshotArtboard} artboard={artboard} guestMode={guestMode} projectKey={projectKey} />}
        <PreviewCameraFunction />
        {/* <ambientLight intensity={0.5} />
      <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} /> */}
        <Sky distance={4500000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
        <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
        {/* <OtherFunctions /> */}
        {artboards[artboard].map((mapped, i) => (
          !mapped.content ?
            (mapped.object === "camera")
              ? ''
              : (mapped.category === "image") ?
                <PreviewImageObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={currentObjectArtboard}
                  objectsAreLoaded={objectsAreLoaded}
                  colour={mapped.colour}
                  pose={mapped.pose}
                  image={mapped.image}
                  imageSize={mapped.imageSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                /> :
                (mapped.category === "figma") ?
                  <PreviewFigmaObject
                    key={i}
                    position={mapped.position}
                    rotation={mapped.rotation}
                    scale={mapped.scale}
                    id={mapped.id}
                    destination={mapped.destination}
                    object={mapped.object}
                    category={mapped.category}
                    currentObjectArtboard={currentObjectArtboard}
                    objectsAreLoaded={objectsAreLoaded}
                    colour={mapped.colour}
                    figmaID={mapped.figmaID}
                    figmaURL={mapped.figmaURL}
                    figmaSize={mapped.figmaSize}
                    matrixState={mapped.matrixState}
                    curved={mapped.curved}
                    curveAmount={mapped.curveAmount}
                  /> :
                                  mapped.databaseId && (mapped.category === "ai") ?
                    <PreviewAIModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      artboard={artboards[currentObjectArtboard]}
                      currentObjectArtboard={currentObjectArtboard}
                      objectsAreLoaded={objectsAreLoaded}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      guestMode={guestMode}
                      projectKey={projectKey}
                      aiKey={mapped.aiKey}
                      databaseId={mapped.databaseId}
                      databaseURL={mapped.databaseURL}
                    /> :
                    mapped.uploadedURL && (mapped.category === "uploaded") ?
                    <PreviewUploadedModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      artboard={artboards[currentObjectArtboard]}
                      currentObjectArtboard={currentObjectArtboard}
                      objectsAreLoaded={objectsAreLoaded}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      guestMode={guestMode}
                      projectKey={projectKey}
                      uploadedURL={mapped.uploadedURL}
                      uploadId={mapped.uploadId}
                    /> :
                    mapped.imageTexture && (mapped.category === "UI") ?
                      <UIModelTexture
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale}
                        id={mapped.id}
                        destination={mapped.destination}
                        object={mapped.object}
                        category={mapped.category}
                        url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                        artboard={artboards[currentObjectArtboard]}
                        currentObjectArtboard={currentObjectArtboard}
                        objectsAreLoaded={objectsAreLoaded}
                        colour={mapped.colour}
                        pose={mapped.pose}
                        imageTexture={mapped.imageTexture}
                        isLocked={mapped.isLocked}
                        matrixState={mapped.matrixState}
                      />
                      :
                      (mapped.imageTexture && mapped.category === "shape") ?
                        <ShapeTexture
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          matrixState={mapped.matrixState}
                        /> :
                        <PreviewModel
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[artboard]}
                          currentObjectArtboard={artboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          matrixState={mapped.matrixState}
                          curved={mapped.curved}
                          curveAmount={mapped.curveAmount}
                          imageTexture={mapped.imageTexture}
                          modelPath={mapped.modelPath}
                        />
            :
            <PreviewText
              key={i}
              content={mapped.content}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              artboard={artboards[artboard]}
              currentObjectArtboard={artboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              bGColour={mapped.bGColour}
              matrixState={mapped.matrixState}
            />
        ))}


        <mesh
          receiveShadow
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, 0, 0]}
        >
          <planeGeometry attach="geometry" args={[50, 50]} />
          <meshStandardMaterial
            color={floorColour}
            attach="material"
            opacity={1}
          />
        </mesh>

      </Canvas>
    </>
)
}




export const ScreenshotForProjectsPage = ({ index, floorColour, artboard, guestMode, projectKey }) => {

  const canvasRef = useRef()

  const {
    previewCameraPosition,
    objectsAreLoaded
  } = useStore();
 

  function PreviewCameraFunction() {
    const { camera } = useThree()
    useFrame(() => {
      camera.position.x = previewCameraPosition[0]
      camera.position.y = previewCameraPosition[1] + 1.75
      camera.position.z = previewCameraPosition[2]

    })
    return null
  }


  return (
<>
    <View index={index}
      style={{  overflow: "hidden", position: "relative", width: "100%", height: 150, top:0, left:0 }}
      >
 <PreviewCameraFunction />
      <OtherFunctions /> 
      {/* <ambientLight intensity={0.5} />
      <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} /> */}
      <Sky distance={4500000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
      <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
      {artboard.map((mapped, i) => (
        !mapped.content ?
          (mapped.object === "camera")
            ? ''
            : (mapped.category === "image") ?
              <PreviewImageObject
                key={i}
                position={mapped.position}
                rotation={mapped.rotation}
                scale={mapped.scale}
                id={mapped.id}
                destination={mapped.destination}
                object={mapped.object}
                category={mapped.category}
                currentObjectArtboard={1}
                objectsAreLoaded={true}
                colour={mapped.colour}
                pose={mapped.pose}
                image={mapped.image}
                imageSize={mapped.imageSize}
                matrixState={mapped.matrixState}
                curved={mapped.curved}
                curveAmount={mapped.curveAmount}
              /> :
              (mapped.category === "figma") ?
                <PreviewFigmaObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={1}
                  colour={mapped.colour}
                  figmaID={mapped.figmaID}
                  figmaURL={mapped.figmaURL}
                  figmaSize={mapped.figmaSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                /> :
                                mapped.databaseId && (mapped.category === "ai") ?
                  <Suspense key={i} fallback={''}>
                    <PreviewAIModel
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      currentObjectArtboard={1}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      guestMode={guestMode}
                      projectKey={projectKey}
                      databaseId={mapped.databaseId}
                      databaseURL={mapped.databaseURL}
                    /></Suspense> :
                    mapped.uploadedURL && (mapped.category === "uploaded") ?
                    <PreviewUploadedModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      uploadedURL={mapped.uploadedURL}
                      uploadId={mapped.uploadId}
                    /> :
                    mapped.imageTexture && (mapped.category === "UI") ?
                      <UIModelTexture
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale}
                        id={mapped.id}
                        destination={mapped.destination}
                        object={mapped.object}
                        category={mapped.category}
                        url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                        colour={mapped.colour}
                        pose={mapped.pose}
                        imageTexture={mapped.imageTexture}
                        isLocked={mapped.isLocked}
                        matrixState={mapped.matrixState}
                      />
                      :
                      (mapped.imageTexture && mapped.category === "shape") ?
                        <ShapeTexture
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          matrixState={mapped.matrixState}
                        /> :
                        <PreviewModel
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={1}
                          currentObjectArtboard={1}
                          objectsAreLoaded={true}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          matrixState={mapped.matrixState}
                          imageTexture={mapped.imageTexture}
                          modelPath={mapped.modelPath}
                          curved={mapped.curved}
                          curveAmount={mapped.curveAmount}
                        />
          :
          <PreviewText
            key={i}
            content={mapped.content}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            artboard={1}
            currentObjectArtboard={1}
            objectsAreLoaded={true}
            colour={mapped.colour}
            bGColour={mapped.bGColour}
            matrixState={mapped.matrixState}
          />
      ))}


      <mesh
        receiveShadow
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, 0, 0]}
      >
        <planeGeometry attach="geometry" args={[50, 50]} />
        <meshStandardMaterial
          color={floorColour && floorColour}
          attach="material"
          opacity={1}
        />
      </mesh>
   </View></>
  )
}









export const ScreenshotStoryboardPOV = ({ index, artboard, guestMode, projectKey }) => {

  const {
    artboards,
    screenshots,
    previewCameraPosition,
    previewCameraRotation,
    objectsAreLoaded,
    currentObjectArtboard,
    floorColour,
    updatePreviewCamera,
    storyboardPOV
  } = useStore();



  function PreviewCameraFunction() {
    const { camera } = useThree()
    // console.log(camera)
    useFrame(() => {
      // camera.position.x = artboards[artboard].find((x) => x.id === "camera").position[0]
      // camera.position.z = artboards[artboard].find((x) => x.id === "camera").position[2]
      camera.position.x = storyboardPOV[currentObjectArtboard].position[0]
      camera.position.y = storyboardPOV[currentObjectArtboard].position[1] + 1.75
      camera.position.z = storyboardPOV[currentObjectArtboard].position[2]

      camera.rotation.x = storyboardPOV[currentObjectArtboard].rotation[0]
      camera.rotation.y = storyboardPOV[currentObjectArtboard].rotation[1]
      camera.rotation.z = storyboardPOV[currentObjectArtboard].rotation[2]
      // camera.rotation.w = storyboardPOV[currentObjectArtboard].rotation[3]

    })
    return null
  }
  const canvasRef = useRef()

  // return <Suspense fallback={<div><img src="/image/loading.gif" style={{width: "50px", height: "50px"}}/></div>}><>
  return <Suspense fallback={<div><img src="/image/loading.gif" style={{ width: "50px", height: "50px" }} /></div>}>
    <>
    {/* <View index={index} 
      style={{  overflow: "hidden", position: "relative", width: "100%", height: "100%", top:0, left:0 }}> */}
        <Canvas
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        camera={{
          position: [storyboardPOV[currentObjectArtboard].position[0], storyboardPOV[currentObjectArtboard].position[1] + 1.75, storyboardPOV[currentObjectArtboard].position[2] + 2],
          rotation: [storyboardPOV[currentObjectArtboard].rotation[0], storyboardPOV[currentObjectArtboard].rotation[1], storyboardPOV[currentObjectArtboard].rotation[2]],
          fov: 55
        }}
        frameloop="demand"
        shadows
        gl={{ preserveDrawingBuffer: true }}
        ref={canvasRef}
      >
        {/* {!guestMode && <TakeScreenshot artboard={artboard} guestMode={guestMode} projectKey={projectKey} />} */}
        <PreviewCameraFunction />
        {/* <ambientLight intensity={0.5} />
      <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} /> */}
        <Sky distance={4500000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
        <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
        {/* <OtherFunctions /> */}
        {artboards[artboard].map((mapped, i) => (
          !mapped.content ?
            (mapped.object === "camera")
              ? ''
              : (mapped.category === "image") ?
                <PreviewImageObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={currentObjectArtboard}
                  objectsAreLoaded={objectsAreLoaded}
                  colour={mapped.colour}
                  pose={mapped.pose}
                  image={mapped.image}
                  imageSize={mapped.imageSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                /> :
                (mapped.category === "figma") ?
                  <PreviewFigmaObject
                    key={i}
                    position={mapped.position}
                    rotation={mapped.rotation}
                    scale={mapped.scale}
                    id={mapped.id}
                    destination={mapped.destination}
                    object={mapped.object}
                    category={mapped.category}
                    currentObjectArtboard={currentObjectArtboard}
                    objectsAreLoaded={objectsAreLoaded}
                    colour={mapped.colour}
                    figmaID={mapped.figmaID}
                    figmaURL={mapped.figmaURL}
                    figmaSize={mapped.figmaSize}
                    matrixState={mapped.matrixState}
                    curved={mapped.curved}
                    curveAmount={mapped.curveAmount}
                  /> :
                                  mapped.databaseId && (mapped.category === "ai") ?
                    <PreviewAIModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      artboard={artboards[currentObjectArtboard]}
                      currentObjectArtboard={currentObjectArtboard}
                      objectsAreLoaded={objectsAreLoaded}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      guestMode={guestMode}
                      projectKey={projectKey}
                      aiKey={mapped.aiKey}
                      databaseId={mapped.databaseId}
                      databaseURL={mapped.databaseURL}
                    /> :
                    mapped.uploadedURL && (mapped.category === "uploaded") ?
                    <PreviewUploadedModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      artboard={artboards[currentObjectArtboard]}
                      currentObjectArtboard={currentObjectArtboard}
                      objectsAreLoaded={objectsAreLoaded}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      guestMode={guestMode}
                      projectKey={projectKey}
                      uploadedURL={mapped.uploadedURL}
                      uploadId={mapped.uploadId}
                    /> :
                    mapped.imageTexture && (mapped.category === "UI") ?
                      <UIModelTexture
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale}
                        id={mapped.id}
                        destination={mapped.destination}
                        object={mapped.object}
                        category={mapped.category}
                        url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                        artboard={artboards[currentObjectArtboard]}
                        currentObjectArtboard={currentObjectArtboard}
                        objectsAreLoaded={objectsAreLoaded}
                        colour={mapped.colour}
                        pose={mapped.pose}
                        imageTexture={mapped.imageTexture}
                        isLocked={mapped.isLocked}
                        matrixState={mapped.matrixState}
                      />
                      :
                      (mapped.imageTexture && mapped.category === "shape") ?
                        <ShapeTexture
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          matrixState={mapped.matrixState}
                        /> :
                        <PreviewModel
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[artboard]}
                          currentObjectArtboard={artboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          matrixState={mapped.matrixState}
                          curved={mapped.curved}
                          curveAmount={mapped.curveAmount}
                          imageTexture={mapped.imageTexture}
                          modelPath={mapped.modelPath}
                        />
            :
            <PreviewText
              key={i}
              content={mapped.content}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              artboard={artboards[artboard]}
              currentObjectArtboard={artboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              bGColour={mapped.bGColour}
              matrixState={mapped.matrixState}
            />
        ))}


        <mesh
          receiveShadow
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, 0, 0]}
        >
          <planeGeometry attach="geometry" args={[50, 50]} />
          <meshStandardMaterial
            color={floorColour}
            attach="material"
            opacity={1}
          />
        </mesh>
      {/* </View>  */}
      </Canvas>
    </>
  </Suspense>
}
