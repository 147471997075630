import React, { useState, useEffect, useRef } from "react";
import { useStore, bringToArtboard } from "../zustand/objects";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// MANTINE
import classes from './BadgeCard.module.css';
import { Card, LoadingOverlay, Input, Text, Group, Badge, ActionIcon } from '@mantine/core';


import { Screenshot } from "../components/Screenshot";
import { isMobile } from 'react-device-detect';
import debounce from 'lodash.debounce';

import { View } from "@react-three/drei";
import { Canvas, useThree, useFrame } from "@react-three/fiber";

const ArtboardPanel = ({ projectKey }) => {
  const {
    storyboardFields,
    artboards,
    removeArtboard,
    currentObjectArtboard,
    updateArtboardAndPreviewCamera,
    updateObjectSelected,
    addArtboardPositional,
    copyArtboardPositional,
    updateStoryboardFields,
    screenshotStatus,
    moveArtboard,
    updateArtboard,
    setScreenshotStatus
  } = useStore();

  const bringUsersHere = () => {
    bringToArtboard(currentObjectArtboard)
  }

  const [artboardViewing, setArtboardViewing] = useState()
  const [editingName, setEditingName] = useState('')

  useEffect(() => {
    setArtboardViewing(currentObjectArtboard)
  },[currentObjectArtboard])

  // Delay function that returns a promise
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const cycleFunction = async () => {
  const artboardKeys = Object.keys(artboards);

  for (let i = 0; i <= artboardKeys.length + 1; i++) {
    const val = artboardKeys[i];
    // console.log(val);

    if (i <= artboardKeys.length) {
      // updateArtboard(val);
      setArtboardViewing(val);
    } else {
      setScreenshotStatus(false);
      setArtboardViewing(currentObjectArtboard);

    }

    // Await the delay of 1000ms before moving to the next iteration
    await delay(2400);
  }
};

  // const cycleFunction = () => {

  //   Object.keys(artboards).forEach((val, i)=>{
  //     console.log(val)
  //     setTimeout(() => {
  //     if (i < Object.keys(artboards).length) {
        
  //         updateArtboard(val)
  //         setArtboardViewing(val)
       
  //     }
  //     else {
  //       setScreenshotStatus(false)
  //     } }, i + 950);
  //  });
  // }

  useEffect(() => {
    screenshotStatus && cycleFunction()
  },[screenshotStatus])


  const handleChange = debounce((artboard, fieldType, value) => {

    updateStoryboardFields(artboard, fieldType, value)

  }, 500)


  const handleSubmit = (e) => { 
    e.preventDefault()
    e.target[0].blur()
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId && 
      destination.index === source.index
    ) {
      return
    }

    moveArtboard(source.index, destination.index)
  } 

const [snapping, setSnapping] = useState(null)

const updateScreenshotArtboard = () => {
  snapping === artboards.length ? setSnapping(null) : setSnapping(snapping + 1)
}

  return <>
    <div style={{ width: "100%", marginTop:20 }}>
      <div>
        <Grid container direction="column" spacing={1} pb={0} mb={0}>
           <DragDropContext
           onDragEnd={onDragEnd}
      // {({ destination, source }) =>
      //   console.log(source,destination)}
    >
      <Droppable droppableId="artboard-list" direction="vertical">
      { (provided) => (
        <div 
        ref={provided.innerRef}
        {...provided.droppableProps}>
          {Object.keys(artboards).map((mapped, i) => (
            <div key={i} style={{width:"auto"}} >
            {/* <div key={i} style={{width:265}}> */}
          <Draggable key={i} index={i} draggableId={i.toString()}>
            {(provided) => (
              <div {...provided.draggableProps} ref={provided.innerRef}>
            <Grid key={i} item  ml={1} mb={1.5}>            
              <Card withBorder radius="md" p={0} style={{ borderRadius: 16, cursor: 'pointer' }}
                // marginLeft:artboardViewing === mapped ? 0 : 30}}
                shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                  
                <Group wrap="nowrap" gap={0}  >
                  <div {...provided.dragHandleProps} style={{position:"absolute", zIndex: 999999, }}>
                <Text c={currentObjectArtboard === mapped ? "#424242" : "#868e96"}><DragIndicatorIcon fontSize="small" /></Text>
                </div>
                  <div >
                  {(artboardViewing === mapped) ? <div style={{ width: 120, height: 85 }}>
                  {/* {(currentObjectArtboard === mapped) ? <div style={{ width: 120, height: 85 }}> */}
                  {/* {(artboardViewing === mapped) ? <div style={{ width: 120, height: 85 }}> */}
                      <Screenshot index={i} updateScreenshotArtboard={updateScreenshotArtboard} snapping={0} projectKey={projectKey} guestMode={false} artboard={mapped} /></div>
                      :
                      <div
                        style={{
                          width: 120, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                          // width: 130, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                        }}
                        onClick={() => {
                          updateObjectSelected('0')
                          updateArtboardAndPreviewCamera(mapped); 
                          setArtboardViewing(mapped);
                        }}
                        size="small" />}
                    {/* SAME AS BELOW BUT SIMPLY CHANGE ARTBOARDVIEWING TO CYCLE THROUGH AND SCREENSHOT EACH ONE */}
                    {/* {(artboardViewing === mapped) ? <div style={{ width: 120, height: 85 }}>
                      <Screenshot index={i} snapping={0} projectKey={projectKey} guestMode={false} artboard={mapped} /></div>
                      :
                      screenshotStatus === true ? 
                      <div style={{ width: 120, height: 85 }}>
                      <Screenshot index={i} snapping={snapping} updateScreenshotArtboard={updateScreenshotArtboard} projectKey={projectKey} guestMode={false} artboard={mapped} /></div>
                      :
                      <div
                        style={{
                          width: 120, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                          // width: 130, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                        }}
                        onClick={() => {
                          updateObjectSelected('0')
                          updateArtboardAndPreviewCamera(mapped); 
                          setArtboardViewing(mapped);
                        }}
                        size="small" />} */}
                  </div>
                  <div className={classes.body} style={{ paddingLeft: 10 }}>
                    {editingName === mapped ?
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <Input
                          style={{ width: 85 }}
                          type='text'
                          autoFocus
                          defaultValue={storyboardFields[mapped].name ? storyboardFields[mapped].name : 'Name'}
                          onChange={(e) => handleChange(mapped, 'name', e.target.value)}
                          onBlurCapture={() => setEditingName('')}

                        />
                        <Input type="submit" hidden />
                      </form>
                      :
                      <Text fw={currentObjectArtboard === mapped && 500} size={'xs'} 
                      c={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                      // c={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: 'nowrap', marginBottom: 10, width: 95 }}
                        onClick={() => {
                          setEditingName(mapped)
                        }}>
                        {mapped}{storyboardFields[mapped].name ? ": " + storyboardFields[mapped].name : " Name"}
                      </Text>}
                    <Stack direction="row" >
                      <ActionIcon
                        onClick={() => {
                          addArtboardPositional(mapped)
                        }}
                        radius={32}
                        // color={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        color={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                        size={16}
                        variant="filled"
                        style={{ marginRight: 10 }}>
                        <AddIcon fontSize="small" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          copyArtboardPositional(mapped);
                        }}
                        // color={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        color={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                        size={16}                       
                        variant="transparent"
                        style={{ marginRight: 10 }}>
                        <ContentCopyIcon fontSize="small" />
                      </ActionIcon>
                      <ActionIcon
                        disabled={currentObjectArtboard == mapped}
                        onClick={() => {
                          removeArtboard(mapped, false)
                        }}
                        // color={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        color={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                        size={16}                        
                        variant="transparent"
                        style={{ marginRight: 10 }}>
                        <DeleteIcon fontSize="small" />
                      </ActionIcon>
                    </Stack>

                  </div>
                </Group>
              </Card>
            </Grid>
            </div>)}
            </Draggable></div>
          ))}
          {provided.placeholder}
          </div>

       )}
          </Droppable>
          </DragDropContext> 
        </Grid></div>
    </div>
   {/* {screenshotStatus && <Canvas
            className="canvas"
            shadows
            style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden' }}
            camera={{
                position: [0, 0, 0],
                fov: 55
            }}
            frameloop="demand"
            gl={{ preserveDrawingBuffer: true }}
        >
            <View.Port />
        </Canvas>
        } */}
  </>;
};

export default ArtboardPanel;















