import React, { useState, useEffect } from 'react';
import { Edges, Plane, Box, Sphere, Circle, Cone, Cylinder, Tube, Torus, TorusKnot, Ring, Tetrahedron, Polyhedron, Icosahedron, Octahedron, Dodecahedron, Extrude, Lathe } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import * as THREE from 'three'
import JsxParser from 'react-jsx-parser'
import { getSpecificAIModel } from '../api'
import { useStore } from "../zustand/objects";


export default function AIModel({ prompt, clickFunction, colour, guestMode, projectKey, aiKey }) {
    const {
        aiModels,
        objectsAreLoaded
    } = useStore();

// console.log(aiKey)

    const [jsx, setJSX] = useState(null)
 

    const thisModel = aiModels.find(item => item.prompt === prompt) && aiModels.find(item => item.prompt === prompt).jsx.replace('<>', "").replace('</>', "").replace(/\s+/g, ' ').trim().replace(/> </g, '><').replace(/\/>/g, "receiveShadow material-color={color} material={MeshStandardMaterial}/>")

    useEffect(() => {
        // !guestMode ? aiModels.length && getSpecificAIModel(prompt).then((res) => setJSX(res.jsx))
        // guestMode && objectsAreLoaded && getSpecificAIModel({prompt: prompt, projectKey: projectKey}).then((res) => setJSX(res.jsx.replace('<>', "").replace('</>', "").replace(/\s+/g, ' ').trim().replace(/> </g, '><').replace(/\//g, "receiveShadow material-color={color} material={MeshStandardMaterial}/")))
        guestMode && objectsAreLoaded && getSpecificAIModel(aiKey).then((res) => setJSX(res.jsx.replace('<>', "").replace('</>', "").replace(/\s+/g, ' ').trim().replace(/> </g, '><').replace(/\/>$/g, "receiveShadow material-color={color} material={MeshStandardMaterial}/>")))

    }, [])

    


    return (
        <group visible={colour ? true : false} dispose={null} onClick={(e) => {
            e.stopPropagation()
            clickFunction()
        }}>
            
            <JsxParser
                componentsOnly={true}
                renderInWrapper={false}
                bindings={{ color: colour, MeshStandardMaterial: new THREE.MeshStandardMaterial }}
                components={{ THREE, Plane, Box, Sphere, Circle, Cone, Cylinder, Tube, Torus, TorusKnot, Ring, Tetrahedron, Polyhedron, Icosahedron, Octahedron, Dodecahedron, Extrude, Lathe }}
                jsx={guestMode ? jsx : objectsAreLoaded && thisModel}
            />

        </group>
    );
}

